<template>
  <b-card>
    <div>
      <b-row style="margin-top:2vh">       
          <b-col
          cols="2"
          class="mb-2"
          style="margin-left: 1vw"
        >
          <div class="demo-inline-spacing" >
            <b-avatar
              variant="light-secondary"
              :text= avatar_letter
              size="4rem"
              />
            <div>
                <h5 class="mb-0">
                  {{ get_members[active_user].nome }}
                </h5>       
            </div>
          </div>
            
        </b-col>

        <b-col
        md="3"
      >
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="UserIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Username</span>
            </th>
            <td class="pb-50">
              {{ get_members[active_user].nome }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="VoicemailIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Email</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ get_members[active_user].email }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="CheckIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Status</span>
            </th>
            <td class="pb-50 text-capitalize">
              <b-badge :variant="possible_status[get_members[active_user].status].variant">
                {{ possible_status[get_members[active_user].status].status }}
              </b-badge>
            </td>
          </tr>
        </table>
      </b-col>
        <b-col
        cols="6"></b-col>
        <b-col 
        cols="6"
        style="margin-top:3vh"
        >
          <b-card
          no-body
          class="border mt-1"
          style="margin-left: 1vw"
        >
            <b-card-header class="p-1">
              <b-card-title class="font-medium-2">
                <feather-icon
                  icon="LockIcon"
                  size="18"
                />
                <span class="align-middle ml-50">Permissões</span>
              </b-card-title>
            </b-card-header>
            <b-table
              striped
              responsive
              class="mb-0"
              :items="permissionsData"
            >
              <template #cell(local)="data">
                {{ data.value }}
              </template>

              <template #cell(Procurar)="data">
                <b-form-checkbox v-model= "search[data.index].data" />
              </template>    

              <template #cell(Criar)="data">
                <b-form-checkbox v-model= "create[data.index].data"/>
              </template>  

              <template #cell(Editar)="data">
                <b-form-checkbox v-model= "edit[data.index].data"/>
              </template> 

              <template #cell(Deletar)="data">
                <b-form-checkbox v-model= "delet[data.index].data"/>
              </template>          

            </b-table>
          </b-card>
        </b-col>

        <b-col md="12">
          <b-button
            style="margin-left: 1vw"
            
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-success"
            @click="edit_permissions()"
            to="/users"
          >
            <feather-icon
              icon="Edit2Icon"
              class="mr-50"
            />
            <span class="align-middle">Confirmar edição</span>
          </b-button>
        </b-col>      
      </b-row>
    </div>
  </b-card>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import vSelect from 'vue-select'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { BFormTags,} from 'bootstrap-vue'
import { BRow, BCol, BFormGroup, BFormInput, BAvatar, BFormSelect, BButton, BFormCheckbox, BForm, BTable, BCard, BCardHeader, BCardTitle, BBadge } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Cookies from 'js-cookie'
import store from '@/store/index'
import axios from "axios";
import api from '@/service/api'

export default {
  components: {
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormTags,
    BAvatar,
    BFormSelect,
    BButton,
    BFormCheckbox,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BBadge,
    ToastificationContent,
  },

  created(){
    var self = this;
    if (Cookies.get("token_api") != null) {
      store.state.token_api = Cookies.get("token_api");
    }
    if(Cookies.get('token_api') == undefined){
      top.location.href = "/login";
    }
    if(store.state.refresh_interval == ''){
      store.state.refresh_interval = setInterval(
      api.refresh_token,
        120000
      );
      store.state.refresh_interval = true;
    }

    self.requests();
  },

  data() {
    
    return {
      edited_search: [],
      edited_create: [],
      edited_edit: [],
      edited_delete: [],
      permissionsData: [], 
      search: [],
      create: [],
      edit: [],
      delet: [],
      avatar_letter: '',
      get_members: [],
      active_user: store.state.selected_member,         

      possible_status: [
        {
         id: 0,
         status: 'Offline',
         variant: 'danger',
       },
       {
         id: 1,
         status: 'Online',
         variant: 'success',
       },
      ],      
      possible_check: [
        {
          id: 0,
          status: 'false'
        },
        {
          id: 1,
          status: 'true'
        }
      ]   
    }
  },
  methods: {
    async requests(){
      var self = this;
      
      let group_request = '?idGroup=' + store.state.active_group.id;
      
      await axios
      .get('https://tekie.cloud:3031/permissions' + group_request, {
        headers: {
          Authorization: this.$store.state.token_api,
          },
        })   
      .then(function (response) {   
        self.get_members = response.data.dadosMembersGroup;
        self.avatar_letter = response.data.dadosMembersGroup[store.state.selected_member].nome.split('',1)[0]
        
        self.permissionsData = [
        {
          local: 'Dispositivos',
          Procurar: self.get_members[self.active_user].searchDevice,
          Criar: self.get_members[self.active_user].writeDevice,
          Editar: self.get_members[self.active_user].editDevice,
          Deletar: self.get_members[self.active_user].deleteDevice,
        },
        {
          local: 'Datas',
          Procurar: self.get_members[self.active_user].searchDatas,
          Criar: self.get_members[self.active_user].writeDatas,
          Editar: self.get_members[self.active_user].editDatas,
          Deletar: self.get_members[self.active_user].deleteDatas,
        },
        {
          local: 'Dashboards',
          Procurar: '0',
          Criar: 0,
          Editar: self.get_members[self.active_user].editDash,
          Deletar: self.get_members[self.active_user].deleteDash,
        },
      ]
      
      self.search = [
        {
          data: self.possible_check[self.get_members[self.active_user].searchDevice].status
        },
        {
          data: self.possible_check[self.get_members[self.active_user].searchDatas].status
        },
        {
          data: self.possible_check[self.get_members[self.active_user].searchDash].status
        },
      ]

      self.create = [
        {
          data: self.possible_check[self.get_members[self.active_user].writeDevice].status
        },
        {
          data: self.possible_check[self.get_members[self.active_user].writeDatas].status
        },
        {
          data: self.possible_check[self.get_members[self.active_user].writeDash].status
        },
      ]

      self.edit = [
        {
          data: self.possible_check[self.get_members[self.active_user].editDevice].status
        },
        {
          data: self.possible_check[self.get_members[self.active_user].editDatas].status
        },
        {
          data: self.possible_check[self.get_members[self.active_user].editDash].status
        },
      ]

      self.delet = [
        {
          data: self.possible_check[self.get_members[self.active_user].deleteDevice].status
        },
        {
          data: self.possible_check[self.get_members[self.active_user].deleteDatas].status
        },
        {
          data: self.possible_check[self.get_members[self.active_user].deleteDash].status
        },
      ]


      })
      .catch(function (error) {
        //error);
        //"Deu erro");
      });
    },
    edit_permissions(){
      
      var self = this;     
      for(let i = 0; i < 3; i++){
        if(self.search[i].data == 'true' || self.search[i].data == true){
          self.edited_search[i] = 1;
        }
        else {
          self.edited_search[i] = 0;
        };
        
        if(self.create[i].data == 'true' || self.create[i].data == true){
          self.edited_create[i] = 1;
        }
        else {
          self.edited_create[i] = 0;
        };

        if(self.edit[i].data == 'true' || self.edit[i].data == true){
          self.edited_edit[i] = 1;
        }
        else {
          self.edited_edit[i] = 0;
        };

        if(self.delet[i].data == 'true' || self.delet[i].data == true){
          self.edited_delete[i] = 1;
        }
        else {
          self.edited_delete[i] = 0;
        };
        
      }

       let data = {
        searchDevice: self.edited_search[0],
        writeDevice: self.edited_create[0],
        editDevice: self.edited_edit[0],
        deleteDevice: self.edited_delete[0],
        searchDatas: self.edited_search[1],
        writeDatas: self.edited_create[1],
        editDatas: self.edited_edit[1],
        deleteDatas: self.edited_delete[1],
        searchDash: self.edited_search[2],
        writeDash: self.edited_create[2],
        editDash: self.edited_edit[2],
        deleteDash: self.edited_delete[2],
        seeAllMembers: 'true',
        editPermissions: 'true',
        groupId: store.state.active_group.id,
        toUser: self.get_members[self.active_user].id,
      };
    
     var patch_device = {
      method: 'patch',
      url: 'https://tekie.cloud:3031/permissions',    
      headers: {
        "Content-Type": "application/json",
        'Authorization': store.state.token_api
      },
      data: data,
    };

     var self = this;
     axios(patch_device)
     .then(function (response) {
       let title = 'Notificação'
       let icon = 'BellIcon'
       let text = 'Dados do dispositivo alterados!'
       let variant = 'success'
       self.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      })
     })

     .catch(function (error) {
       //error);
       //self.get_members[self.active_user].id)
       let title = 'Notificação'
       let icon = 'BellIcon'
       let text = 'Falha ao tentar atualizar os dados'
       let variant = 'danger'
       self.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
          },
        })
      });
    },
    showToast(variant, title, icon, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      })
    },
  },
}


</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>